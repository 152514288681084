@font-face {
    font-family: 'trebuchet_msregular';
    src: url('/static/assets/fonts/trebuc-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;

}

$f-one: 'Ubuntu', sans-serif;
$f-two: 'trebuchet_msregular', sans-serif;

// Colors //

$c-one: #E86066;
$c-two: #BD5887;
$c-three: #80598F;
$c-four: #b6c3c3;
$c-five: #47557B;
$c-six: #F08232;
$c-eight: #79C8FF;
$c-nine: #d9d9d9;

// paddings //

$p1: 4rem 0;
$p2: 4rem 0;
$p3: 3rem 0;

// Shadows //

$shadow:  0px 4px 10px rgba(0, 0, 0, 0.25);



// Transitions //

$transition: all .4s ease-in;
$transitionLong: all 1s linear;
$transitionExtraLong: all 40s linear;


@mixin breakpoint($point) {
    @if $point == bigDesktop {
        @media (min-width: 1431px) {
            @content;
        }
    } @else if $point == fullHd {
        @media (max-width: 1960px) {
            @content;
        }
    }
    @else if $point == upPhablet {
        @media (min-width: 680px) {
            @content;
        }
    }
    @else if $point == onlyDesktop {
        @media (min-width: 920px) {
            @content;
        }
    } @else if $point == mediumDesktop {
        @media (max-width: 1720px) {
            @content;
        }
    }
    @else if $point == smallDesktop {
        @media (max-width: 1430px) {
            @content;
        }
    } @else if $point == smallerDesktop {
        @media (max-width: 1230px) {
            @content;
        }
    } @else if $point == tablet {
        @media (max-width: 960px) {
            @content;
        }
    } @else if $point == smallComputer {
        @media (max-height: 765px) and (min-width: 960px) {
            @content;
        }
    } @else if $point == phablet {
        @media (max-width: 780px) {
            @content;
        }
    } @else if $point == newMobile {
        @media (max-width: 500px) and (min-height: 700px) {
            @content;
        }
    } @else if $point == mobileonly {
        @media (max-width: 485px) {
            @content;
        }
    } @else if $point == smallMobile {
        @media (max-width: 330px) {
            @content;
        }
    }
}

html {
    font-size: 10px;
    font-family: $f-one;
}