.team {
    padding: $p2;
    position: relative;
    .center__btn {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
    &__row {
        display: flex;
        gap: 4rem;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 7rem;
    }
    &__member {
        flex: 0 0 20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1.5rem;
        text-decoration: none;
        color: black;
        &--alumni {
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
            flex: 0 0 100%;
            gap: 0;
            a {
                text-decoration: none;
            }
        }
        h5 {
            color: $c-three;
            text-transform: uppercase;
            min-height: 3.6rem;
        }
        p {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
        figure {
            width: 14.8rem;
            height: 14.8rem;
            border-radius: 100%;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.positions {
    margin-top: 7rem ;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap;
}

.position {
    width: 26rem;
    height: 26rem;
    flex: 0 0 26rem;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 2rem;
    flex-direction: column;
    text-decoration: none;
    color: white;
    font-size: 1.4rem;
    line-height: 1.8rem;
    overflow: hidden;
    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: linear-gradient(180deg, rgba(225, 185, 204, 0) 26.56%, rgba(189, 88, 135, 0.9) 100%);
        left: 0;
        z-index: 2;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: $transition;
    }
    span {
        position: relative;
        z-index: 3;
    }
    &__category {
        display: inline-block;
        padding: .5rem 2rem;
        margin-left: -2rem;
        background: $c-two;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 2rem;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        letter-spacing: 2px;
    }
    &:hover {
        img {
            transform: scale(1.05)
        }
    }
}