.search {
    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .container {
        padding: 1.5rem 3rem;
        display: flex;
        align-items: center;
        gap: 3rem;
        .btn {
            position: relative;
        }
    }
    form {
        position: relative;
        z-index: 2;
        max-width: 34rem;
        width: 100%;
        input {
            height: 3.2rem;
            width: 100%;
            max-width: 34rem;
            border-radius: 3rem;
            border: none;
            transition: $transition;
            box-sizing: border-box;
            padding: 0 5rem 0 2rem;
            &:focus {
                outline: none;
                background: #f9f9f9;
            }
        }
        button {
            width: 4.1rem;
            height: 100%;
            background: $c-six;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 3rem 3rem 0;
            border: none;
            margin-right: -1rem;
            cursor: pointer;
        }
    }
}