.contact {
    padding: $p1;

    .row {
        justify-content: center;
        margin-top: 4rem;
        gap: 13rem;
        @include breakpoint(tablet) {
            gap: 5rem;
        }
        .col {
            flex: 1;
            text-align: center;
            h4,h3 {
                color: $c-three;
            }
            p {
                font-size: 1.6rem;
                line-height: 2rem;
            }
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                font-size: 2.4rem;
                font-weight: 500;
                margin-top: 2rem;
                text-decoration: none;
                color: $c-three;
            }
            figure {
                img {
                    width: 23rem;
                    height: 23rem;
                    border-radius: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    &__us {
        margin: $p1;
        padding: $p2;
        position: relative;
        color: white;
        @include breakpoint(tablet) {
            padding: 3rem 0 0;
            margin-bottom: 0;
        }
        .title {
            color: white;
            margin-bottom: 3rem;
        }
    }
    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        @include breakpoint(tablet) {
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__data {
        display: flex;
        align-items: center;
        gap: 3rem;
        margin-top: 6rem;
    }
}
#map {
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    @include breakpoint(tablet) {
        position: relative;
        width: 100%;
        height: 40vh;
        margin-top: 4rem;
    }
}
