.articles {
    padding: $p2;
    position: relative;
    &__single {
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 3rem;
        padding-top: 10rem;
        margin-bottom: -6rem;
        &:last-child {
            margin-bottom: 3rem;
        }
        @include breakpoint(tablet) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        img {
            max-width: 32rem;
            height: auto;
            display: block;
            width: 100%;
        }
        figure {
            flex: 0 0 10%;
            position: relative;
            @include breakpoint(tablet) {
                width: 100%;
            }
            span {
                position: absolute;
                top: 1rem;
                left: 0;
                padding: .5rem 2rem;
            }
        }
    }
    &__date {
        font-size: 2.4rem;
        line-height: 3.6rem;
        font-weight: 700;
        color: $c-nine;
        letter-spacing: 2px;
        span {
            color: $c-six;
            display: block;
            padding-top: 1rem;
            position: relative;
            margin-top: 1rem;
            &::before {
                content: '';
                display: block;
                height: 2px;
                width: 3.3rem;
                position: absolute;
                left: 0;
                top: 0;
                background: $c-nine;
            }
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 3;
        color: black;
        font-size: 1.6rem;
        line-height: 2.4rem;
        max-width: calc(100vw - 6rem);
        overflow: hidden;
    }
    &__title {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $c-three;
        text-transform: uppercase;
        font-weight: 700;
        font-family: $f-two;
        letter-spacing: 2px;
        margin-bottom: 1.2rem;
    }
    &__project {
        color: black;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 400;
    }
    &__desc {
        font-size: 1.6rem;
        color: black;
        line-height: 2.4rem;
        a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            max-width: 100%;
            vertical-align: middle;
        }
        .single__line {
            display: block;
            &.date {
                font-size: 1.2rem;
                line-height: 1.8rem;
            }
            &.organizer {
                font-size: 1.6rem;
                line-height: 1.8rem;
                margin-bottom: 2rem;
                font-weight: bold;
            }
            &.room {
                font-size: 1.6rem;
                line-height: 2rem;
            }

        }
    }
    &__other {
        .articles__date {
            span {
                padding-bottom: 1rem;
                &::before {
                    bottom: 0;
                    top: initial;
                }
            }

        }
    }
    &__news {
        .articles__single {
            figure {
                position: relative;
                overflow: hidden;
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }

        }
    }
}

.pagination {
    margin: 7rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    a {
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: 2px;
        color: $c-six;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        border: 2px solid $c-six;
        border-radius: 100%;
        transition: $transition;
        &:hover,
        &.active {
            background: $c-six;
            color: white;
        }
    }
}

.publications {
    .articles__single {
        figure {
            max-width: 8.5rem;
            min-width: 8.5rem;
        }
    }
    .articles__desc {
        a {
            text-decoration: none;
            color: $c-one;
            display: block;
            font-size: 1.8rem;
            line-height: 3.2rem;
        }
    }
}

.news {
    &__default {
        figure span {
            background: $c-two;
            color: white;
        }
    }
    &__news {
        figure {
            &::before {
                background: linear-gradient(180deg, rgba(255, 208, 169, 0) 32.29%, rgba(240, 130, 50, 0.9) 100%);
            }
        }
        .articles__title {
            color: $c-six;
        }
        figure span {
            background: $c-six;
            color: white;
        }
    }
    &__job {
        figure::before {
            background: linear-gradient(180deg, rgba(225, 185, 204, 0) 26.56%, rgba(189, 88, 135, 0.9) 100%);
        }
        .articles__title {
            color: $c-two;
        }
    }
    &__project {
        figure::before {
            background: linear-gradient(180deg, rgba(140, 156, 199, 0) 32.29%, rgba(71, 85, 123, 0.9) 100%);
        }
        .articles__title {
            color: $c-five;
        }
    }
    &__media {
        .articles__title {
            color: $c-eight;
        }
        .news__tile {
            color: white;
        }
        figure span {
            background: $c-eight;
            color: white;
        }
    }
    &__publication {
        figure::before {
            background: linear-gradient(180deg, rgba(232,96,102,0) 0%, rgba(232,96,102,.9) 100%);
        }
        .articles__title {
            color: $c-one;
        }
        figure span {
            background: $c-one;
            color: white;
        }
    }
    &__events {
        figure::before {
            background: linear-gradient(180deg, rgba(71,85,123,0) 0%, rgba(71,85,123,.9) 100%);
        }
        .articles__title {
            color: $c-five;
        }
        figure span {
            background: $c-five;
            color: white;
        }
    }

}