html {
    scroll-behavior: smooth;
}

.container {
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 0 3rem;
    max-width: 134rem;
}

main {
    margin-top: 8rem;
    @include breakpoint(tablet) {
        margin-top: 6rem;
    }
    ul {
        margin: 2rem;
        li {
            position: relative;
            padding-left: 4rem;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 2rem;
                height: 1.5rem;
                background-image: url(/static/assets/img/list.svg);
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}

.row {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
    .col {
        flex: 0 0 calc(50% - 3rem);
        @include breakpoint(tablet) {
            flex: 0 0 100%;
        }
    }
}

.search {
    margin: 6rem 0 -4rem;
    position: relative;
    z-index: 2;
}
