h1, h2 {
    font-size: 3rem;
    line-height: 4.2rem;
    font-weight: bold;
    font-family: $f-two;
    letter-spacing: 2px;
    @include breakpoint(tablet) {
        font-size: 2rem;
        line-height: 3.2rem;
    }
}
h3 {
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: bold;
    font-family: $f-two;
    letter-spacing: 2px;
}
h4 {
    font-size: 2rem;
    line-height: 3rem;
    font-family: $f-two;
}
h5,
h6 {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 700;
    font-family: $f-two;
    letter-spacing: 2px;
}

p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 300;
}
main {
    li {
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-weight: bold;
        letter-spacing: 2px;
    }
}


strong,
b {
    font-weight: bold !important;
    letter-spacing: 2px;
}

.text-center {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.title {
    margin-bottom: 4rem;
    position: relative;
    display: block;
    color: $c-three;
    &::after {
        content: '';
        display: block;
        height: 2px;
        width: 9rem;
        left: 0;
        bottom: -1rem;
        background: $c-six;
        position: absolute;
    }
    &.text-center {
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &__full {
        &::after {
            width: 100%;
        }
    }
}