.single__research {
    margin: $p2;
    position: relative;
    @include breakpoint(tablet) {
        margin-top: 0;
        margin-bottom: 3rem;
    }
    .single__img {
        position: absolute;
        left: 0;
        width: 50%;
        height: 50rem;
        @include breakpoint(tablet) {
            position: relative;
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__desc {
        margin-left: 50%;
        width: 50%;
        box-sizing: border-box;
        padding-left: 4rem;
        min-height: 50rem;
        margin-bottom: 15rem;
        @include breakpoint(tablet) {
            width: 100%;
            margin-left: 0;
            margin-bottom: 3rem;
            min-height: auto;
            margin-top: 4rem;
            padding-left: 0;
        }
        h2 {
            margin-bottom: 2rem;
        }
        p {
            margin-bottom: 2rem;
            strong {
                color: $c-six;
            }
        }
    }
    &__boxes {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 4rem;
    }
    &__box {
        flex: 0 0 25rem;
        text-align: center;
        border: 2px solid $c-six;
        box-sizing: border-box;
        padding: 3rem 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        color: $c-five;
        a {
            text-decoration: none;
        }
        figure {
            width: 5rem;
            height: 5rem;
            margin: 0 auto 1rem;
            img {
                width: 5rem;
                height: 5rem;
                object-fit: contain;
            }
        }
        h5 {
            font-size: 2rem;
            color: $c-five;
            span {
                color: $c-six;
                display: block;
                margin-top: 2rem;
            }
        }
        p {
            font-size: 1.4rem;
            line-height: 1.9rem;
            font-weight: 500;
        }
    }
}