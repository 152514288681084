.research {
    position: relative;
    padding: 4rem 0;
    @include breakpoint(tablet) {
        padding: 0;
    }
    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: bottom center;
        }
    }
    &__img2 {
        position: absolute;
        width: 60%;
        height: 96%;
        top: 2%;
        left: 20%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
    &__img3 {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 70%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .container {
        padding: 10rem 3rem;
        background: $c-one;
        @include breakpoint(tablet) {
            padding: 4rem 3rem;
        }
    }
    &__content {
        padding: 3rem 4rem;
        background: white;
        position: relative;
        z-index: 3;
        width: 50%;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 3rem;
        margin-right: -3rem;
        text-decoration: none;
        @include breakpoint(tablet) {
            padding: 3rem;
            width: calc(100% + 6rem);
            margin-right: 0;
            box-sizing: border-box;
            margin-left: -3rem;
            flex-direction: column;
        }
        &--title {
            position: relative;
            padding-left: 3rem;
            font-weight: 400;
            letter-spacing: 0;
            font-size: 2.4rem;
            line-height: 3.6rem;
            font-family: $f-two;
            color: $c-three;
            &::before {
                content: '';
                display: block;
                height: 100%;
                width: 5px;
                background: $c-six;
                position: absolute;
                left: 0;
                top: 0;
            }
            strong {
                display: block;
                color: $c-six;
                font-weight: 400;
                letter-spacing: 2px;
            }
        }
    }
}