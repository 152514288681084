.services {
    position: relative;
    margin: $p1;
    &__img {
        position: absolute;
        right: 0;
        top: 20%;
        max-width: 50%;
        z-index: -1;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    h2,
    h3 {
        color: $c-three;
        text-transform: uppercase;
    }
    .row {
        margin-bottom: 10rem;
        align-items: center;
        gap: 4.5rem;
        justify-content: space-between;
        @include breakpoint(tablet) {
            flex-direction: row;
        }
        &:nth-child(odd) {
            flex-direction: row-reverse;
        }
        img {
            max-width: 100%;
            height: auto;
        }
        .col {
            &:first-child {
                flex: 0 0 calc(35% - 4.5rem);
                @include breakpoint(tablet) {
                    flex: 0 0 100%;
                }
            }
            &:last-child {
                flex: 0 0 calc(65% - 4.5rem);
                @include breakpoint(tablet) {
                    flex: 0 0 100%;
                }
            }
        }
    }
}