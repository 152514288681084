.header {
    &__slide {
        position: relative;
        background: $c-three;
        padding: 3.5rem 0;
        &::before {
            content: '';
            width: calc(50% - 30rem);
            position: absolute;
            top: 3.5rem;
            left: 0;
            height: calc(100% - 7rem);
            display: block;
            background: white;
            box-shadow: $shadow;
            z-index: 2;
            @include breakpoint(smallDesktop) {
                width: calc(50% - 10rem);
            }
            @include breakpoint(tablet) {
                width: 100%;
            }
        }
    }
    &__img {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 70%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: right;
        }
    }
    &__content {
        width: calc(50% - 30rem);
        padding: 9rem 4rem 9rem 0;
        position: relative;
        box-sizing: border-box;
        z-index: 3;
        display: flex;
        flex-direction: column;
        @include breakpoint(smallDesktop) {
            width: calc(50% - 10rem);
        }
        @include breakpoint(tablet) {
            width: 100%;
        }
        h2 {
            color: $c-three;
            margin: 3rem 0;
            text-transform: uppercase;
        }
        .btn {
            margin-top: 4rem;
            margin-left: auto;
            align-self: flex-end;
        }
        p {
            a {
                color: $c-one;
                text-decoration: none;
            }
        }
    }
    &__slider {
        .header__content {
            padding: 9rem 4rem 3.5rem 0;
        }
    }

    &__team {
        .header__slide {
            background-color: $c-two;
        }
    }
    &__news {
        .header__slide {
            background-color: $c-six;
        }
    }
    &__single {
        position: relative;
        padding: 6rem 0;
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(140, 156, 199, 0) 32.29%, rgba(71, 85, 123, 0.9) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
        }
        .header__img {
            width: 100%;
        }
        .header__content {
            background: white;
            padding: 4rem;
            text-align: center;
            position: relative;
            z-index: 3;
        }
        .container {
            display: flex;
            justify-content: center;
        }
        &__research {
            .header__content {
                width: auto;
                p {
                    font-size: 2rem;
                    line-height: 2.6rem;
                }
            }
        }
    }
    &__member {
        background: $c-five;
        color: white;
        .header__content {
            flex-direction: row;
            align-items: center;
            text-align: left;
            gap:2rem;
            background: inherit;
            width: 100%;
            @include breakpoint(phablet) {
                flex-direction: column;
                padding: 0;
            }
            h2 {
                font-size: 2rem;
                line-height: 2.4rem;
                margin-bottom: .5rem;
                color: white;
            }
            p {
                a {
                    color: white;
                    font-size: 2rem;
                    @include breakpoint(phablet) {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}

.tns-outer {
    position: relative;
}

.tns-nav {
    margin: 0 auto;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    padding: 0 3rem;
    max-width: 134rem;
    left: 50%;
    top: 0;
    transform: translate(-50%, 8rem);
    z-index: 5;
    display: flex;
    align-items: center;
    gap: 1rem;
    button {
        width: 2.1rem;
        height: 2.1rem;
        flex: 0 0 2.1rem;
        border-radius: 3rem;
        border: 2px solid $c-six;
        transition: $transition;
        background: white;
        &.tns-nav-active {
            background: $c-six;
        }
    }
}