.btn {
    text-transform: uppercase;
    color: white;
    transition: $transition;
    border: 1px solid $c-three;
    background: $c-three;
    border-radius: 5rem;
    padding: .5rem 2.2rem;
    text-decoration: none;
    font-size: 1.8rem;
    display: inline-block;
    font-family: $f-two;
    box-shadow: $shadow;
    &:hover {
        background: white;
        color: $c-three;
    }
    &--second {
        border-color: white;
        color: white;
        background: rgba(255,255,255,0);
        &:hover {
            background: white;
            color: $c-five;
        }
    }
    &--third {
        border-color: $c-three;
        color: $c-three;
        background: rgba(255,255,255,0);
        &:hover {
            background: $c-three;
            color: white;
        }
    }
}

.center__btn {
    display: flex;
    justify-content: center;
    align-items: center;
}