.banner {
    margin: $p1;
    .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        figure {
            position: absolute;
            width: 55%;
            height: 100%;
            top: 0;
            left: 3rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &__content {
        margin: 2.4rem 0;
        padding: 3rem 4rem;
        background: white;
        box-shadow: $shadow;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        flex: 0 0 55%;
        justify-content: space-between;
        gap: 2rem;
        text-decoration: none;
        @include breakpoint(tablet) {
            flex: 0 0 90%;
            padding: 2rem;
            margin: 6rem 0;
            flex-direction: column;
            align-items: flex-start;
        }
        &--title {

            font-weight: 400;
            text-transform: uppercase;
            padding-left: 3rem;
            border-left: 5px solid $c-six;
            text-decoration: none;
            font-size: 3rem;
            line-height: 4.2rem;
            font-family: $f-two;
            letter-spacing: 2px;
            color: $c-three;
            @include breakpoint(tablet) {
                font-size: 2rem;
                line-height: 3.2rem;
            }
            span {
                display: block;
                color: $c-six;
            }
        }
    }
}