.news {
    margin: $p1;
    .btn {
        margin: 3rem auto 0;
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        justify-content: space-between;
        a {
            flex: 0 0 calc( 25% - 2rem);
            aspect-ratio: 1 / 1;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            color: white;
            text-decoration: none;
            font-size: 1.4rem;
            line-height: 1.7rem;
            transition: $transition;
            @include breakpoint(smallerDesktop) {
                flex: 0 0 calc(33% - 2rem)
            }
            @include breakpoint(tablet) {
                flex: 0 0 calc(50% - 2rem);
            }
            @include breakpoint(phablet) {
                flex: 0 0 100%;
            }
            &:hover {
                transform: scale(1.05);
            }
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
            }
            span {
                margin-left: 2rem;
                &:last-child {
                    margin-bottom: 2rem;
                }
                &.category {
                    margin-left: 0;
                    margin-top: 3rem;
                    margin-bottom: 1rem;
                    padding: .5rem 2rem;
                    background: $c-six;
                    font-size: 2rem;
                    text-transform: uppercase;
                    display: inline-block;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }
            &.news__news {

            }
        }
        span {
            position: relative;
            z-index: 3;
        }
        .news__news {

            &::before {
                background: linear-gradient(180deg, rgba(255, 208, 169, 0) 32.29%, rgba(240, 130, 50, 0.9) 100%);
            }

        }
        .news__default {
            .category {
                background: $c-two;
            }
        }
        .news__job {

            transform: scale(1.1);
            .category {
                background: $c-two !important;
            }
            &::before {
                background: linear-gradient(180deg, rgba(225, 185, 204, 0) 26.56%, rgba(189, 88, 135, 0.9) 100%);
            }
        }
        .news__project {
            .category {
                background: $c-five !important;
            }
            &::before {
                background: linear-gradient(180deg, rgba(140, 156, 199, 0) 32.29%, rgba(71, 85, 123, 0.9) 100%);
            }
        }
        .news__media {
            .category {
                background: $c-eight !important;
                color: white;
            }
        }
        .news__publication {
            .category {
                background: $c-one !important;
            }
            &::before {
                background: linear-gradient(180deg, rgba(232,96,102,0) 0%, rgba(232,96,102,.9) 100%);
            }
        }
        .news__events {
            background: $c-three;
            gap: 2rem;
            span {
                width: calc(100% - 4rem);
                text-align: center;
            }
            .category {
                background: $c-three;
                width: auto;
            }

        }

    }

    &__twitter {
        background:$c-eight;
        text-align: center;
        justify-content: center !important;
        align-items: center !important;
        gap: 2rem;
        span {
            font-size: 2rem;
            line-height: 2.8rem;
        }
        .btn {
            box-shadow: none;
            margin: 0 !important;
        }
        img {
            width: 7.8rem !important;
            height: 5.9rem !important;
            object-fit: contain !important;
            position: relative !important;
        }
    }

    &__title {
        text-align: center;
        font-size: 1.4rem;
        line-height: 2rem;
        width: 100%;
        padding: 0 1rem 2rem;
        margin-left: 0 !important;
        justify-self: center;
        box-sizing: border-box;
        &.uppercase {
            margin-top: 3rem;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: .8rem;
            height: 2px;
            width: 4.4rem;
            left: 50%;
            transform: translateX(-50%);
            background: $c-six;
        }
    }

}