.equipment {
    padding: $p1;
    position: relative;
    &__img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
        }
    }
    &__list {
        background: $c-three;
        box-sizing: border-box;
        padding: 4rem;
        color: white;
        margin-bottom: 4rem;
        @include breakpoint(tablet) {
            padding: 2rem;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            @include breakpoint(tablet) {
                gap: 1rem;
            }
            li {
                &:nth-child(odd) {
                    flex: 0 0 55%;
                    @include breakpoint(tablet) {
                        flex: 0 0 100%;
                    }
                }
                strong {
                    color: $c-six;
                }
            }
        }
    }
    .our-services {
       padding: $p2;
        justify-content: space-between;
        @include breakpoint(tablet) {
            gap: 4rem;
            padding: $p1;
        }
        &__buttons {
            margin-top: 2rem;
        }
        .col {
            &:nth-child(2) {
                flex: 0 0 30%;
                text-align: center;
                @include breakpoint(tablet) {
                    flex: 0 0 100%;
                }
            }
        }
        &__avatar {
            width: 23.8rem;
            height: 23.8rem;
            border-radius: 100%;
            overflow: hidden;
            margin: 0 auto 1rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        h4 {
            color: $c-three;
        }
    }
}