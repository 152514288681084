.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    padding: 1.6rem 0;
    box-shadow: $shadow;
    z-index: 10;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 3rem;
    }
    &__logos {
        display: flex;
        align-items: center;
        gap: 3rem;
        @include breakpoint(smallerDesktop) {
            gap: 1.5rem;
        }
        @include breakpoint(tablet) {
            gap: 3rem;
        }
        img {
            height: 6rem;
            width: auto;
            @include breakpoint(smallerDesktop) {
                height: 4rem;
            }
            @include breakpoint(tablet) {
                height: 2.8rem;
                width: auto;
            }
        }
    }
    &__menu {
        @include breakpoint(tablet) {
            position: absolute;
            left: 0;
            width: 100%;
            height: calc(100vh - 6rem);
            top: 5rem;
            background: rgba(54, 35, 62, 0.95);
            transition: $transition;
            transform: translateX(-110%);
            &.active {
                transform: translateX(0);
            }
        }
        ul {
            display: flex;
            align-items: center;
            gap: 3rem;
            @include breakpoint(smallerDesktop) {
                gap: 1.5rem;
            }
            ul {
                gap: 1rem;
            }
            @include breakpoint(tablet) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                height: 100%;
                box-sizing: border-box;
                padding: 3rem;
                ul {
                    flex-direction: row;
                    padding: 0;
                }
            }
            a {
                text-decoration: none;
                font-size: 1.6rem;
                color: black;
                transition: $transition;
                text-transform: uppercase;
                @include breakpoint(smallerDesktop) {
                    font-size: 1.4rem;
                }
                @include breakpoint(tablet) {
                    color: white;
                }
                &.active, &:hover {
                    color: $c-six;
                }
            }
        }
    }
    &__btn {
        display: none;
        @include breakpoint(tablet) {
            width: 3.3rem;
            display: block;
            height: 3.4rem;
            position: relative;
            cursor: pointer;
            span {
                width: 2rem;
                height: 2px;
                display: block;
                background: $c-three;
                position: absolute;
                transition: $transition;
                border-radius: 1px;
                &:first-child {
                    top: 30%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &:nth-child(2) {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &:last-child {
                    top: 70%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            &.active {
                span {
                    &:first-child {
                        top: 50%;
                        transform: translate(-50%, -50%) rotate(45deg);
                    }
                    &:nth-child(2) {
                        width: 0;
                    }
                    &:nth-child(3) {
                        top: 50%;
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
            }
        }
    }
}