.footer {
    padding: 4rem 0;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        height: 100%;
        right: 0;
        bottom: 0;
        width: 100%;
        background-image: url(/static/assets/img/footer.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        @include breakpoint(phablet) {
            width: 100%;
            border-top: 2.3rem solid $c-six;
            border-left: none;
            height: calc(100% - 13rem);
        }
    }
    .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 5rem;
        @include breakpoint(phablet) {
            flex-direction: column;
        }
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
        position: relative;
        z-index: 2;
        justify-content: flex-end;
        > p {
            flex: 0 0 calc(100% - 12rem);
            color: white;
            text-align: right;
            @include breakpoint(phablet) {
                flex: 0 0 100%;
            }
        }
    }
    &__menu {
        width: 9rem;

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include breakpoint(phablet) {
                flex-wrap: wrap;
                margin-top: 2rem;
                gap: 1rem;
            }
            li {
                @include breakpoint(phablet) {
                    flex: 0 0 calc(50% - 1rem);
                    &:last-child {
                        flex: 0 0 100%;
                    }
                }

            }
            ul {
                gap: 1rem;
                @include breakpoint(phablet) {
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-top: 0;
                }
                li {
                    @include breakpoint(phablet) {
                        flex: 0 0 auto;
                        &:last-child {
                            flex: 0 0 auto
                        }
                    }

                }
            }
            a {
                text-transform: uppercase;
                color: white;
                text-decoration: none;
                font-size: 1.4rem;
                transition: $transition;
                &:hover,
                &.active {
                    color: $c-six;
                }
            }
        }
    }
    &__row {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
        nav {
            a {
                color: white;
                text-decoration: none;
                font-size: 1.4rem;
                transition: $transition;
                &:hover,
                &.active {
                    color: $c-six;
                }
            }
        }
    }
    &__publisher {
        font-size: 1.4rem;
        line-height: 2rem;
        color: white;
        text-align: right;
        a {
            color: white;
            transition: $transition;
            text-decoration: none;
            &:hover {
                color: $c-six;
            }
        }
    }
}