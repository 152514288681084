.member {
    padding: $p1;
    p,
    a,
    li {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: 2rem;
    }
    h3, h2, h4,
    h5, h6, h1{
        margin-top: 2rem;
        color: $c-three;
    }
    &__icon {
        width: 1.8rem;
        height: 1.8rem;
        object-fit: contain;
        display: inline-block;
        margin-right: 1.5rem;
        vertical-align: middle;
        margin-left: 2.5rem;
    }
    &__info {
        display: flex;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
        figure {
            width: 20rem;
            height: 20rem;
            overflow: hidden;
            border-radius: 100%;
            flex-basis: 20rem;
            img {
                width: 102%;
                height: 102%;
                object-fit: cover;
                transform: translate(-1%, -1%);
            }
        }
        a {
            text-decoration: none;
            color: $c-one
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $c-two;
        }
    }
}